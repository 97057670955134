.tc-slot {
    border-right: 1px solid rgba(207, 207, 207, 0.75);
}

.mbsc-timeline-event {
    padding-top: 4px;
}

.mbsc-timeline-row-gutter {
  height: 4px;
}

.mbsc-timeline-resource-col {
    width: 200px;
}

.tc-sticky-event-content {
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
    vertical-align: top;
    overflow: hidden;
}

@supports (overflow: clip) {
    .tc-sticky-event-content {
        position: sticky;
        top: 0;
        left: 202px;
    }
}

@media screen and (max-width: 767px) {
    .mbsc-timeline-resource-col {
        width: 125px;
    }

    @supports (overflow: clip) {
        .tc-sticky-event-content {
            left: 127px;
        }
    }
}

@media screen and (max-width : 575px) {
    .mbsc-timeline-resource-col {
        width: 85px;
    }

    @supports (overflow: clip) {
        .tc-sticky-event-content {
            left: 87px;
        }
    }
}

.mbsc-timeline-resource-title {
    padding: 0;
}

.mbsc-timeline-resource-header {
    height: 100%;
    padding: 0 8px 0 0;
}

.tc-zoom-week .mbsc-timeline-header-week,
.tc-zoom-month .mbsc-timeline-header-week {
    min-height: 52px;
}

.tc-zoom-week .mbsc-timeline-header-week-text,
.tc-zoom-month .mbsc-timeline-header-week-text {
    margin-top: 26px;
}

.mbsc-material.mbsc-calendar-controls {
    padding: 0;
}

.mbsc-timeline-resource-title {
    height: 100%;
}

.mbsc-timeline-sidebar-col {
    width: 100px;
}

.mbsc-timeline-sidebar-resource-title {
    height: 100%;
}

.mbsc-timeline-sidebar-header {
    height: 100%;
}

.mbsc-calendar {
    height: auto;
    max-height: 100%;
}

.mbsc-calendar-controls {
    min-height: unset;
}

.tc-add-calendars:after {
    display: block;
    content: "";
    width: 255px;
    height: 189px;
    position: absolute;
    left: 50%;
    margin-top: 25px;
    margin-left: 95px;
    background: transparent url('../tutorial/add_calendar_arrow.png') no-repeat;
}

@media print {
    .tc-scheduling-view {
        height: 100%;
    }

    .tc-hidden-print {
        display: none !important;
    }
}

@media not print {
    .tc-scheduling-view {
        height: calc(100vh - 64px - 22px);
    }

    @supports selector(::-webkit-scrollbar) {
        .mbsc-timeline-grid-scroll::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        .mbsc-timeline-grid-scroll::-webkit-scrollbar:vertical {
            width: 11px;
        }

        .mbsc-timeline-grid-scroll::-webkit-scrollbar:horizontal {
            height: 11px;
        }

        .mbsc-timeline-grid-scroll::-webkit-scrollbar-thumb {
            border-radius: 4px;
            border: 2px solid white; /* should match background, can't be transparent */
            background-color: rgba(0, 0, 0, 0.5);
            box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        }
    }
}

.timeline-export {
    .mbsc-timeline {
        -moz-box-flex: 0 !important;
        flex: none !important;
        overflow: visible !important;
    }

    .mbsc-timeline-grid-scroll {
        overflow-x: visible !important;
        overflow-y: visible !important;
    }

    .mbsc-timeline-grid {
        height: auto !important;
        overflow: visible !important;
        width: auto !important;
    }

    .mbsc-timeline-row {
        page-break-inside: avoid;
    }

    .mbsc-schedule-fake-scroll-y,
    .mbsc-schedule-time-indicator {
        display: none;
    }

    .tc-hidden-print {
        display: none !important;
    }
}